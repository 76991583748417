h1, h2, h3, h4, h5 {
  i {
    font-style: normal;
    color: $secondary-color;
  }
}


.margin-bottom-1 {
  margin-bottom: 1em;
}
.margin-bottom-2 {
  margin-bottom: 2em;
}
.margin-bottom-3 {
  margin-bottom: 3em;
}
.margin-bottom-4 {
  margin-bottom: 4em;
}
.margin-top-1 {
  margin-top: 1em;
}
.margin-top-2 {
  margin-top: 2em;
}
.margin-top-3 {
  margin-top: 3em;
}
.margin-top-4 {
  margin-top: 4em;
}


.small-print {
  font-size: 14px;
}
