.pb4 {
  padding-bottom: 4em;
}
.grid-container--small {
  max-width: 48rem !important;
}

.grid-container--medium {
  max-width: 64rem !important;
}

.grid-container--full {
  max-width: 100% !important;
  padding-left: 0;
  padding-right: 0;
}


.grid-x>.medium-160 {
  @include breakpoint(large) {
    width: 306px;
  }
  @include breakpoint(xlarge) {
    width: 306px;
  }
}



.grid-container--circles-1 {

    position: relative;
    z-index: 0;

    &::before, &::after {
      position: absolute;
      content: '';
      display: block;
      clip-path: circle(50%);
      top: 0;
      z-index: -3;
      pointer-events: none;
    }

    &::before {
      background: transparent;
      border: 6px dotted $yellow;
      border-radius: 50%;

      height: 40vw;
      width: 40vw;

      right: -2vw;
      top: -8vw;

      @include breakpoint(large) {
        border: 10px dotted $yellow;

        height: 32em;
        width: 32em;

        right: -8vw;
        top: -2vw;
      }
    }

    &::after {

      background: linear-gradient(90deg, rgba($green, 0) 0%, rgba($green, 1) 100%);
      right: 5vw;
      top: 5vw;
      height: 32em;
      width: 32em;
    }

    &.scroll-reveal-animation::before, &.scroll-reveal-animation::after {
      clip-path: circle(0%);
      transition: clip-path 2s cubic-bezier(0.65, 0, 0.35, 1);
    }

     &.scroll-reveal-animation::after {
      transition-delay: .6s;
    }

    &.scroll-reveal-animation.active::before, &.scroll-reveal-animation.active::after {
        clip-path: circle(50%);
    }


}




.grid-container--circles-2 {

    position: relative;
    z-index: 0;

    &::before, &::after {
      position: absolute;
      content: '';
      display: block;
      clip-path: circle(50%);
      top: 0;
      z-index: -3;
      pointer-events: none;
      opacity: .8;

      right: -15em;
      top: 25%;

      @include breakpoint(large) {
        top: 50%;
      }


    }

    &::before {
      background: linear-gradient(90deg, rgba($magenta, .5) 0%, rgba($magenta, 1) 100%);
      border: 2.5em solid $cyan ;

      border-radius: 50%;

      height: 40em;
      width: 40em;
      transform: translate(-5em, -50%);

    }

    &::after {

      background: linear-gradient(90deg, rgba($yellow, .5) 0%, rgba($yellow, 1) 100%);
      border: 2.5em solid $green ;
      border-radius: 50%;

      height: 30em;
      width: 30em;
      transform: translate(-10em, -50%);

    }

    &.scroll-reveal-animation::before, &.scroll-reveal-animation::after {
      clip-path: circle(0%);
      transition: clip-path 2s cubic-bezier(0.65, 0, 0.35, 1);
    }

    &.scroll-reveal-animation.active::before, &.scroll-reveal-animation.active::after {
      clip-path: circle(50%);
    }


}




.grid-container--circles-3 {

  position: relative;
  z-index: 0;

  &::after {
    position: absolute;
    content: '';
    display: block;
    clip-path: circle(50%);
    top: 0;
    z-index: -3;
    pointer-events: none;

    @include breakpoint(medium down) {
      display: none;
    }

  }


  &::after {

    background: linear-gradient(180deg, rgba($green, 0) 0%, rgba($yellow, 1) 100%);
    right: -20vw;
    top: 10vw;
    height: 48em;
    width: 48em;
  }

  &.scroll-reveal-animation::after {
    clip-path: circle(0%);
    transition: clip-path 2s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.scroll-reveal-animation.active::after {
      clip-path: circle(50%);
  }


}










.cell--circle-behind {

  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    content: '';
    display: block;

    background: $light-grey;
    clip-path: circle(50%);
    pointer-events: none;

    left: 50%;
    transform: translate(-50%, -54%);
    top: 50%;
    z-index: -3;
    height: 120%;

    aspect-ratio: 1 / 1;

    @supports not (aspect-ratio: 1 / 1) {
      height: 70vh;
      width: 70vh;
    }
  }

  &.scroll-reveal-animation::before {
    clip-path: circle(0%);
    transition: clip-path 2s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.scroll-reveal-animation.active::before {
      clip-path: circle(50%);
  }

}

.cell {
  z-index: 1;
}

.cell--rich-text {
  gap: 1em;
  display: flex;
  flex-direction: column;
  p {
    max-width: 55ch;
  }
  a {
    word-wrap: break-word;

    &.button {
      width: max-content;
    }
  }
  ul {
    list-style: none;
    margin: 0 0 2em 0;
    li {
      position: relative;
      padding-left: 2em;
      margin-bottom: .5em;
      max-width: 55ch;

      &::before {
        content: url('../img/icon-green-tick.svg');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }

      b {
        display: block;
      }

    }
  }
  // trying to remove any margin form the end of a last element
  & >:last-child {
    margin-bottom: 0;
  }
}
