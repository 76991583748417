$cyan: #36C5F1;
$magenta: #FECCD5;
$yellow: #ECB22D;
$green: #2FB67C;
$purple: #5275C2;

$red: $magenta;
$blue: $cyan;
$light-blue: #B6DDE5;
$light-green: #D3FFF1;

$gray: #dadada;
$thirdly-color: $yellow;

$light-gray: #F3F0EE;
$light-grey: $light-gray;
$dark-gray: #999999;
$dark-grey: $dark-gray;

$white: #ffffff;

$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #2C3843;
$white: #fefefe;

$dark: #06121E;
