.hero {

  padding-top: 7em !important;
  position: relative;


  @include breakpoint(large) {
    padding-top: calc(3em + 125px) !important;
  }



  &--bground {

    &-white {
      background: $white;
    }

  }

  &--centered-text {

    &.section {

      @include breakpoint(large) {
        padding-bottom: 13em;
      }

    }

    .toggle-ball {
      bottom: 0;
    }

  }

}

.hero__object {

  @include breakpoint(xxlarge) {
    width: 150%;
    max-width: 150%;
  }
}

.hero__content {
  display: flex;
  flex-direction: column;
  gap: 1em;

  // Fix for iOS14
  & > * {
    @supports (-webkit-touch-callout: none) and (not(translate: none)) {
      margin: 0 0 1em 0 !important;
    }
  }
}

.hero__h1{
  margin: 0;
  font-size: 28px;

  @include breakpoint(large) {
      font-size: 50px;
  }
}

.hero__h1--big{
  margin: 0;
  font-size: 28px;
  line-height: 1.2em;

  @include breakpoint(large) {
      font-size: 70px;
  }
}

.hero__h2 {
  margin: 0;
  font-weight: normal;
  line-height: 1.4em;
  font-size: 22px;


  @include breakpoint(large) {
      font-size: 30px;
      max-width: 22ch;
  }

}

.hero__description{
  margin: 0;
  font-size: 18px;
  max-width: 36ch;

  ul {
    list-style: none;
    margin: 0;
    li {
      position: relative;
      padding-left: 2em;
      margin-bottom: .5em;
      max-width: 55ch;

      &::before {
        content: url('../img/icon-green-tick.svg');
        display: block;
        position: absolute;
        left: .5em;
        top: 0;
      }

      b {
        display: block;
      }

    }
  }

  &--centered {
    max-width: 72ch;
    margin: 0 auto;
  }

}

.hero__description--big{
  margin: 0;
  font-weight: normal;
  line-height: 1.4em;
  font-size: 22px;

  @include breakpoint(large) {
      font-size: 30px;
  }
}

.hero__icon {
  max-width: 70px;
  height: auto;
}

.hero__button {
  width: fit-content;
  margin-bottom: 0;
}

.hero__logo {
  max-width: 160px;
}
