.button {
  &.secondary {
    color: $white;

    &:hover {
      color: $white;
    }
  }
  &.purple {
    background: $purple;
    color: $white;

    &:hover {
      color: $white;
      background: darken($purple, 20);
    }
  }
}
.button {
  &[target="_blank"]{
    &::after {
      content: url('../img/icon-new-window.svg');
      display: inline-block;
      vertical-align: middle;
      margin-left: 1em;
    }
  }
}
