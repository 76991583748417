.footer{
  padding: 4em 0 0;

  background-color: rgba($secondary-color, .27);
}

.footer__section{
  margin: 4em 0 0;

  padding: 1em 0;

  background: $black;
  color: $white;
}

.menu--footer {
  color: $white;
  justify-content: flex-start;

  @include breakpoint(medium) {
    justify-content: flex-end;
  }

  a {
    color: $white;
    font-size: 12px;
  }
}

.copyright {
  font-size: 12px;
}
