section.scroll-reveal-animation {

  .grid-container {
    opacity: 0;
    margin-top: 100px;
    transition: margin-top .6s cubic-bezier(0.65, 0, 0.35, 1), opacity .6s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active {
    .grid-container{
      opacity: 1;
      margin-top: 0;
    }
  }

}

.section {
  padding: 4em 0;
}

.section--cards {
  position: relative;
}

.section--dark-grey {
  position: relative;
  background-color: $black;

  .cell--rich-text {
    color: $white;
    p, li, h1, h2, h3, h4, h5  {
      color: $white;
      i {
        font-style: normal;
        color: $secondary-color;
      }
    }
  }
}

.section--target-white-center {
  overflow-x: hidden;
  overflow-x: clip;
  overflow-y: visible;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-image: url("../img/target-white-center.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;

    width: 400px;
    height: 400px;
    bottom: -330px;

    z-index: 1;

  }
  &.scroll-reveal-animation {
    opacity: 0;
    transition: opacity .3s ease-out;

    &::after{
      transform: translateX(-140%);
      transition: transform 2s ease-out;
    }


    &.active {
      opacity: 1;
      &::after {
        transform: translateX(0);
      }
    }
  }
}
.section--target-white-center--right {

  &::after {
    z-index: -1;
    bottom: 50%;
    left: 56%;
    top: 53%;

  }

}



.section--barn-left{
  overflow-x: hidden;
  overflow-x: clip;
  overflow-y: visible;
  position: relative;

    &::before{
      content: "";
      display: block;
      position: absolute;
      width: 55%;
      height: 700px;
      border-radius: 0 700px 700px 0;
      bottom: -130px;
      left: 0;
    }


    &.scroll-reveal-animation {
      opacity: 0;
      transition: opacity .3s ease-out;

      &::before{
        transform: translateX(-140%);
        transition: transform 2s ease-out;
      }


      &.active {
        opacity: 1;
        &::before {
          transform: translateX(0);
        }
      }
    }
}

.section--barn-right{
  overflow-x: hidden;
  overflow-x: clip;
  overflow-y: visible;
  position: relative;

    &::before{
      content: "";
      display: block;
      position: absolute;
      width: 55%;
      height: 700px;
      border-radius:  700px 0 0 700px;
      bottom: -130px;
      right: 0;
    }


    &.scroll-reveal-animation {
      opacity: 0;
      transition: opacity .3s ease-out;

      &::before{
        transform: translateX(140%);
        transition: transform 2s ease-out;
      }

      &.active {
        opacity: 1;
        &::before {
          transform: translateX(0);
        }
      }
    }
}


.section--barn-blue  {
    &::before{
      background-color: rgba($secondary-color, .27);
    }
}
.section--barn-green  {
    &::before{
      background-color: rgba($primary-color, .27);
    }
}
.section--barn-magenta  {
    &::before{
      background-color: rgba($magenta, .27);
    }
}
.section--barn-purple  {
    &::before{
      background-color: rgba($purple, .27);
      bottom: 0;
    }
}




.section--barn-right-dark-green {

  overflow: hidden;
  overflow: clip;

  position: relative;

  background-image: url("../img/shape-mesh.svg");
  background-repeat: no-repeat;
  background-position: 5% 80%;

    &::before{
      content: "";
      display: block;
      position: absolute;
      background-color: rgba($primary-color, 1);
      width: 55%;
      height: 700px;
      border-radius: 400px 0 0 400px;

      width: 100%;
      height: 400px;

      bottom: 100px;
      right: 0;
    }

    &::after{
      content: "";
      display: block;
      position: absolute;
      background-image: url("../img/shape-blue-arrows.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      pointer-events: none;

      width: 200%;
      height: 160%;
      top: -26%;
      left: -95%;

      @include breakpoint(large) {
        width: 100%;
        height: 90%;
        top: 5%;
        left: 0;
      }
    }


    // styling in section, TIM

    padding: 15em 0;

    h3 {
      color: $white;
      font-weight: normal;
      font-family: $body-font-family;
      font-weight: 30px;

      margin: 0 3em;

      @include breakpoint(large) {
        margin: 0;
      }

      a {
        font-weight: bold;
        color: $white;
        text-decoration: underline;
      }
    }

    &.scroll-reveal-animation {
      opacity: 0;
      transition: opacity 1s ease-out;

      h3 {
        opacity: 0;
        transition: opacity 1s 1.5s ease-out;
      }

      &::before{
        transform: translateX(120%);
        transition: transform 1.5s cubic-bezier(0.65, 0, 0.35, 1);
      }


      &.active {
        opacity: 1;
        &::before {
          transform: translateX(2%);

          @include breakpoint(large) {
            transform: translateX(10%);
          }
        }
        h3 {
          opacity: 1;
        }
      }
    }

}





.section--barn-right-blue {
  overflow-x: hidden;
  overflow-x: clip;
  overflow-y: visible;
  position: relative;

  &::before, &::after {
    content: "";
    display: block;
    position: absolute;

    background-color: lighten($secondary-color, 40);
    border-radius:  400px 0 0 400px;

    width: 40%;
    height: 400px;

    top: -100px;
    right: 0;
    // position: relative;
    z-index: -1;
  }

  &::after {

    width: 55%;
    height: 700px;

    top: 200px;
    right: 0;
  }


  &.scroll-reveal-animation {
    opacity: 0;
    transition: opacity .3s ease-out;

    &::before, &::after {
      transform: translateX(120%);
      transition: transform 2s ease-out;
    }

    &::after {
      transform: translateX(140%);
    }

    &.active {
      opacity: 1;
      &::before, &::after {
        transform: translateX(0);
      }
    }
  }

}

object.scroll-reveal-animation {
  opacity: 0;
  transition: opacity 1s ease-out;

  &.active {
    opacity: 1;
  }
}
