.postcode-search {

    width: fit-content;

    form {
        position: relative;
    }

    &__input {
        margin-bottom: 0;
        border-radius: 50px;
        border: 1px solid rgba($black, .5);
        padding: 1.5em;
    }

    &__button {
        margin: 0 !important;
        position: absolute;
        right: -1px;
        top: 0;
        height: 100%;
        background: none;
        border-radius: 50px;

        &:hover {
            background: none;
        }
    }

    &__button-image {
        height: 100%;
        width: auto;
    }

}

.newsletter-form {

    width: fit-content;
    margin: auto;

    form {
        position: relative;
    }

    &__input {
        margin-bottom: 0;
        border-radius: 50px;
        border: 1px solid rgba($black, .5);
        padding: 1.5em 6em 1.5em 1.5em;
    }

    &__button {
        margin: 0 !important;
        position: absolute;
        right: -1px;
        top: 0;
        height: 100%;

        border-radius: 50px;

        &:hover {

        }
    }



}

textarea {
  min-height: 4em;
  margin-top: .5em;
}

select {
  min-height: 2em;
  margin-top: .5em;
}

label {
  color: $white;
  border-bottom: .5em;
  font-weight: 500;
  font-size: 16px;

  &.required {
    &::after {
      color: $green !important;
    }
  }
}

input[type="checkbox"] {

  appearance: none;
  background-color: $white;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  vertical-align: middle;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
  top: .15em;
  left: .1em;
  position: absolute;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline:  0.15em solid  rgba($black, .2);
  outline-offset: 0.15em;
}

input[type="checkbox"]:disabled {
  --form-control-color: $light-grey;

  color: $light-grey;
  cursor: not-allowed;
}


.checkbox-columns {
  columns: 1;

  @include breakpoint(large) {
    columns: 2;
  }
}

.checkbox-columns__checkbox{
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    top: 0.5em;
  }

  [type='checkbox']+label, [type='radio']+label {
    color: $white;
    font-weight: normal;
    margin-bottom: 1em;
    margin-left: 1.8em;
  }

}
.checkbox-columns__legend{
  font-weight: bold;
}

.form-container {
  background: $black;
  color: $white;
  border-radius: $global-radius;
  margin: 1em;
  padding: 2em;
  box-shadow: 0 6px 9px -1px rgba(0,0,0,0.1);

  @include breakpoint(large) {
    margin: 0 2em;
    padding: 4em;
  }

  button {
    @extend .button;
  }
}

.errors {
  list-style: none;
  margin: 0 0 1em;

  li {
    background-color: $magenta;
    color: $black;
    border-radius: 6px;
    padding: 0.6em;
    font-size: .8em;
  }

}
