#navbar {
  position: fixed;
  top: 0;
  width: 100vw;
  width: calc(100vw - (100vw - 100%));
  transition: all 0.3s ease-out;

  &.show-nav {
    top: -175px;
  }
  &.white-nav {
    background: $white;
  }
}


.header {
  position: relative;
  z-index: 101;

  @include breakpoint(large) {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .logo-link{
      // position: absolute;
      // width: 306px;
      // height: 30px;
      // top: 0;
      //
      // @include breakpoint(large) {
      //   width: 306px;
      //   height: 30px;
      // }
      // @include breakpoint(xlarge) {
      //   width: 306px;
      //   height: 30px;
      // }
  }

  .logo {
    width: 100%;
    height: auto;
    vertical-align: middle;
    max-width: 306px;
    padding: 1em;

    &.stacked  {
      max-width: 188px;
    }

  }
}



.main-menu {

  @include breakpoint(large) {
    ul {
      position: initial;
    }
  }

  li {

    &.active {
      width: max-content;
      a:not(.button) {
        background: transparent;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          bottom: 0;
          height: 4px;
          border-radius: 2px;
          background-color: $primary-color;
        }
      }
    }
  }


  a:not(.button) {

    font-size: 18px;
    font-weight: 500;
    transition: color ease-out .3s;

    color: $black;


    @include breakpoint(large) {
        padding: 0.4rem 1rem !important;
    }
    @include breakpoint(1220px) {
        padding: 0.4rem 1.2rem !important;
    }

    &.active {
      color: $primary-color;
    }

    &:hover {
      color: $primary-color;
    }
  }

  .button {
      color: $white;
      &:hover {
        color: $white;
      }
  }
  li {
    a.button:not(.clear) {
      padding: $button-padding;
      width: 100%;
      margin: 1em 0;
      font-size: 18px;
      font-weight: 500;

      @include breakpoint(medium) {
          width: auto;
          margin: 0;
      }
    }

  }

  //parent for dropdown menu
  .is-dropdown-submenu-parent{

    a:not(.menu__extra-a) {
      padding-right: 1.4rem !important;

      &::after {
        content: '';
        border: 0 !important;
        height: .6em !important;
        width: .6em !important;
        background: url('../img/down-chevron.svg') no-repeat center center !important;
        background-size: contain !important;

        // margin-top: -.5em !important;

      }
    }

    @include breakpoint(large) {
      position: initial;
    }
  }

  //child for dropdown menu
  .is-dropdown-submenu {

    &.js-dropdown-active {

      display: block !important;
      position: relative;
      border: 0;
      // background: rgba($thirdly-color, .11);
      margin: 0 -2em;
      padding: 2em;

      @include breakpoint(large) {

        position: absolute;

        top: 110px !important;
        z-index: 200;

        margin: 0 auto;

        width: 100%;
        left: 0;

        padding: 0 0 3em 0;
        display: inline-flex !important;

        flex-flow: row;
        flex-wrap: wrap;

        &> * {
          animation: fadeIn .2s normal forwards ease-out;
        }

        &::before {
          content: '';
          display: block;
          background: #fefefe;
          margin-left: -100%;
          top:0;
          left: 0;
          height: 100%;
          position: absolute;
          width: 1000%;
          z-index: -1;
          box-shadow: 0px 6px 9px -1px rgba(0, 0, 0, .04);
        }

      }

      @media screen and (min-width: 1440px) {
          width: 1440px;
          left: 50% !important;
          // left: 64% !important;
          transform: translateX( -720px );
      }




      li{

        @media screen and (min-width: 1008px) {
          flex: 0 0 33%;
          padding: 2em;
          border-radius: $global-radius;
          transition: background ease-out .3s;


          &:hover{
            background: rgba($thirdly-color, .11);

          }
        }

        @media screen and (min-width: 1440px) {
          flex: 0 0 25%;

        }


        a {
          padding: 0;
          display: block;
          color: $secondary-color;

          &::after {
            background: none !important;
          }

          div[role=title] {
            margin: 0 0 1em 0;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            color: $dark-gray;
          }
        }
      }
    }
  }

  // Mobile menu
  &.show-menu {

    display: block !important;
    background: $white;
    position: absolute;
    z-index: 5;
    width: 100%;
    left: 0;
    height: calc(100vh - 100px);
    overflow: scroll;

    transform-origin: top left;


    padding: 2em;

    ul {
      background-color: transparent;

      li {
        margin: 0;

        a {
          width: 100%;
          padding: .5em 0 !important;
          display: block;


        }

      }
    }
  }
}





.menu--feature {

    padding: 0 !important;

    a.menu__card-link {
      padding-right: 0 !important;
      padding: 0 !important;

      .card__arrow {
          transition: transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);
      }

      &:hover {
        color: inherit;

        .card__arrow {
            transform: translateX(2em);
        }
      }




    }

    a:not(.menu__extra-a).menu__card-link {
      padding-right: 0 !important;

      &::after{
        display: none;
      }
    }

    .menu__details {

      margin: 1em;
      padding: 1em 1.4em 1.4em;
      // box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.1);
      background: $light-grey;
      border-radius: $global-radius;
      overflow: hidden;
      overflow: clip;

      @include breakpoint(large) {
        margin: 3.5em 1em 1em;
        padding: 1em 2.5em 2em;
      }

      .menu__image{
        max-height: 200px;
        width: 100%;
        object-fit: contain;
        text-align: center;
      }

      .menu__image-cover{
        max-height: 200px;
        max-width: initial;
        object-fit: cover;
        aspect-ratio: 5 / 4;

        margin: -1em -1.5em 0;
        width: calc(100% + 2.8em);;

        @include breakpoint(large) {
          margin: -1em -2.5em 0;
          width: calc(100% + 5em);;
        }
      }

      .menu__title{
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.2em;
        // color: $secondary-color;
        margin: 1em 0;

        @include breakpoint(large) {
          margin: 2em 0 1em;
        }
      }

      .menu__subtitle{
        color: $primary-color;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        margin-bottom: 1em;

        @include breakpoint(large) {
          // font-size: 28px;
        }
      }
      .menu__description{
        display: none;
        font-size: 16px;
        line-height: 1.4em;

        @include breakpoint(large) {
          display: block;
          margin: 1.6em 0;
          max-width: 44ch;
        }
      }

      .menu__button {
        margin: 0;
        padding: 0;
        margin-bottom: 2em;

        @include breakpoint(large) {
          width: fit-content !important;
        }
        &::after {
          display: none !important;
        }
      }

    }
    .menu__items {
      padding: 1em;
      margin: 0;

      @include breakpoint(large) {
        // padding: 2em 2.5em;
        margin: 1em;
        margin-top: 2.4em;
        display: flex;
        flex-grow: 1;

        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
      }

      .menu__item-block{

        padding: 0;
        margin: 1em;

        @include breakpoint(large) {
            width: inherit;
            flex-grow: 1;
        }
        p {
          margin: 0.8em 0 0 0;
          color: $secondary-color;
          font-weight: 400;
          font-size: 18px;

          @include breakpoint(large) {
            margin: 0;
          }
          b, strong {

          }
        }
        br {
          display: none;
        }
        ul {

          list-style: none;

          padding: 1em 1.4em;
          margin: 1em 0;

          background: $light-grey;

          border-radius: $global-radius;

          @include breakpoint(large) {
            padding: 1.4em 2em;
            margin: 1em 0;
          }

          li {
            background: none !important;
            padding: .2em  0;
            margin: 0;

            a {
              color: $black;
              padding: .5em 0 !important;
              transition: color .3s ease-out;

              line-height: 1.4em;
              margin: -0.2em 0;

              &:hover {
                color: $primary-color !important;
              }

              &::after {
                display: none !important;
              }
            }

            .button {
                color: $white;
                &:hover {
                  color: $white;
                }
            }
          }
        }

        &:nth-of-type(1n) {
          ul {
            background: rgba($cyan, .1);
          }
        }
        &:nth-of-type(2n) {
          ul {
            background: rgba($green, .1);
          }
        }
        &:nth-of-type(3n) {
          ul {
            background:  rgba($yellow, .1);
          }
        }
      }
    }

}

.menu__extra-cta{
    display: block;
    padding: 1.4em 0;
    width: 100%;
}

.menu__extra-cta-title{
  font-size: 22px;
  font-weight: bold;
  color: $secondary-color;
}

.menu__extra-a {

  padding: 0 !important;
  margin: 0.8em 0!important;

  font-size: 18px;

}

.menu__extra-a-tel  {
  @include breakpoint(large) {
    text-decoration: none !important;
    color: inherit !important;
  }
}


.menu__sub-heading{
  font-weight: bold;
}
