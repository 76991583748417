

.reveal-video {

  position: fixed;

  top: 0;

  width: 100vw;
  height: 100vh;

  padding: 2em;
  border: none;


  background-color: rgba($black, .8);

  transition: all 1.2s ease-in-out;
  transition-delay: 0s;

  opacity: 0 !important;
  display: block !important;
  pointer-events: none !important;

  z-index: 2;

  &.open {
    opacity: 1 !important;
    display: block !important;
    pointer-events: auto !important;

    transition: all 1.2s ease-in-out;
    transition-delay: 1.5s;

  }

  .close-button {
    top: -1em;
    right: -0.5em;

    color: $white;
  }



}

.reveal-video__packaging{
  position: relative;

  max-width: 75rem;
  width: 100%;

  margin: auto;
  top: 50%;
  transform: translateY(-50%);

  aspect-ratio: 16 / 9;

  @supports not (aspect-ratio: 16 / 9) {
    height: 56%;
  }


}

.reveal-video__iframe{
  border-radius: 1em;
  overflow: hidden;
}
