.logo-wall{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding:  0;

  @include breakpoint(large) {
    padding:  0 3em;
  }

  &__logo-container{

    margin: 1em;
    align-self: flex-start;
    place-self: center;
    text-align: center;

    width: calc(50% - 2em);

    @include breakpoint(medium) {
      width: calc(33.33% - 2em);
    }
    // @include breakpoint(large) {
    //   width: calc(20% - 3em);
    // }
  }

  &__logo {

    max-height: inherit;
  }


}
