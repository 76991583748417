.pricing {
  background: $black;
  border-radius: $global-radius;
  color: $white;
  padding: 3em;

  &__section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    grid-gap: 1em;
    justify-content: space-between;

    div {
      flex: 0 0 auto;
    }

    &+.pricing__section {
      border-top: 1px solid $light-green;
      padding-top: 3em;
      margin-top: 3em;
    }

  }
  &__title {
    color: $light-blue;
    font-size: 26px;
    width: 100%;

    @include breakpoint(large) {
      width: calc(50% - .5em);
    }

  }
  &__description {
    width: 100%;

    @include breakpoint(large) {
      width: calc(50% - .5em);
    }
  }
  &__breakdown {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    grid-gap: .5em 2em;
    justify-content: flex-start;
  }
  &__label {
    width: 100%;
  }
  &__cost-setup {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: $green;

  }
  &__cost-platform {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: $light-blue;
  }
  &__list {
    width: 100%;
    list-style: none;
    margin: 0 0 2em 0;
    columns: 1;
    @include breakpoint(large) {
      columns: 2;
    }
    li {
      position: relative;
      padding-left: 2em;
      margin-bottom: .5em;
      max-width: 55ch;

      &::before {
        content: url('../img/icon-green-tick.svg');
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }

      b {
        display: block;
      }

    }

  }

}
